import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {isNullOrUndefined} from '../common';
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private readonly localStorageKey = 'darkMode';
    private readonly isDefaultDarkModeValue = true;

    constructor(@Inject(DOCUMENT) private document: Document) {
        if (!environment.production) {
            this.initializeDarkMode();
        }
    }

    private isDarkModeSet(): boolean {
        return !isNullOrUndefined(localStorage.getItem(this.localStorageKey));
    }

    private isDarkModeEnabled(): boolean {
        return localStorage.getItem(this.localStorageKey) === 'true';
    }

    private initializeDarkMode(): void {
        this.setDarkMode(this.isDarkModeSet() ? this.isDarkModeEnabled() : this.isDefaultDarkModeValue);
    }

    public toggleDarkMode(): void {
        const newDarkModeState = !this.isDarkModeEnabled();
        this.setDarkMode(newDarkModeState);
    }

    setDarkMode(enabled: boolean): void {
        this.document.body.classList.toggle('dark', enabled);
        localStorage.setItem(this.localStorageKey, String(enabled));
    }

    setFocusMode(isFocusMode: boolean = true): void {
        this.document.body.classList.toggle('focus', isFocusMode);
    }

    openSidenav(): void {
        (document.querySelector('cm-sidenav') as any).openSidenav();
    }

    closeSidenav(): void {
        (document.querySelector('cm-sidenav') as any).closeSidenav();
    }
}
